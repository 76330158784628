import { Header } from "components/index";

const InstructionPage = () => {
  return (
    <div>
      <Header />
      Instruction
    </div>
  );
};

export default InstructionPage;
