import { Header } from "components/index";

const ProfilePage = () => {
  return (
    <div>
      <Header />
      Profile
    </div>
  );
};

export default ProfilePage;
